<template>
  <div class="website__domain-connect">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect title="Domain" route="website.domain" />
      </v-col>
      <v-col cols="12 page-header">
        <h2 class="mb-1 page-title">Connect Existing Domain</h2>
        <!-- <router-link :to="{ name: 'website.domain.search' }" class="text-decoration-none">+ Buy new domain</router-link> -->
      </v-col>
      <v-col cols="12" class="elevation-form px-5 py-5 mt-5">
        <Notification v-if="errors.length > 0" :messages="errors" type="error" />
        <div>
          <InputText
            fieldName="Domain"
            :isValidate="true"
            label="Domain"
            :model="['host']"
            placeholder="e.g. example.com"
          />
          <small>Enter the domain you want to connect</small>
        </div>
        <div class="mt-5 text-right">
          <v-btn @click="submit" :loading="isLoading" :disabled="isLoading" color="primary">Connect</v-btn>
        </div>
      </v-col>
      <!-- <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
        <div style="width: 624.00px;margin: auto;">
          <p>
            Để sử dụng tên miền của riêng bạn, bạn cần thêm 2 bản ghi trong phần cài đặt DNS như sau:
          </p>
          <p>
            <b>Bản ghi A (A record)</b> trỏ đến <b>18.217.43.33</b>
            <br />
            <b>Bản ghi CNAME (CNAME record)</b> trỏ đến <b>connect.dcomcy.com</b>
          </p>
          <img
            alt=""
            src="/admin/img/image1.png"
            style="width: 624.00px; height: 89.27px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <p>+ Trên cloudflare, bạn vào domain-> DNS</p>
          <p>+ Trên namecheap, bạn vào domains -> Advance DNS</p>
          <p>
            + Lưu ý: bạn cần tắt proxy (chuyển hình đám mây màu cam thành màu xám hoặc DNS Only) cho cả bản ghi A và
            CNAME
          </p>
          <img
            alt=""
            src="/admin/img/image3.png"
            style="width: 624.00px; height: 161.93px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <img
            alt=""
            src="/admin/img/image2.png"
            style="width: 624.00px; height: 77.73px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
        </div>
      </v-col> -->
    </v-row>
  </div>
</template>
<script>
import InputText from '@/views/website/components/inputs/InputDefault';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { domainService } from '@/apis/domain.s';
import event from '@/plugins/event-bus';

import { helpers } from 'vuelidate/lib/validators';
const domain = helpers.regex('domain', /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/);

export default {
  components: {
    InputText,
  },
  data() {
    return {
      host: null,
      errors: [],
      isLoading: false,
    };
  },
  validations: {
    host: {
      required,
      domain,
    },
  },
  methods: {
    async submit() {
      if (!this.$v.host.$invalid) {
        this.isLoading = true;
        try {
          let domainData = await domainService.connect({ host: this.host });
          this.$router.push({ name: 'website.domain' });
          event.$emit('getStepStatus', message => {
            this.getStep();
          });
          this.isLoading = false;
        } catch (error) {
          this.errors = error;
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
